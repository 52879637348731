@use "@els/els-styleguide-core/scss/core" as *;

button {
  color: $els-color-text;
}

button:focus-visible,
[type="button"]:focus-visible,
[type="reset"]:focus-visible,
[type="submit"]:focus-visible {
  @include focus-default;
}
