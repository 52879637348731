@use "@els/els-styleguide-core/scss/core" as *;

.c-hra-hesi-base {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 1px;

  &__header {
    padding: $els-space-3o4;
    border-bottom: $els-thin-keyline-width solid $els-color-n3;
  }

  &__page {
    background-color: $els-color-n1;
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 1px;
    margin: $els-space-2x;
  }

}
